import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ModerationFeedData } from './useDocumentModeration';
import { FormGrid, LoadingIndicator, Tooltip, useThrottledState } from '../../toolympus/components/primitives';
import { ThemeSettings } from '../../theme';
import { Button, Fab, Paper, TextField, Typography } from '@mui/material';
import { Add, Check, CheckCircleOutlineOutlined, CommentOutlined, RadioButtonUnchecked, Send } from '@mui/icons-material';
import { useFormats } from '../../toolympus/components/schemed';
import { Buttons } from '../../toolympus/components/PowerDoc/elements/Common';
import { utc } from '../../toolympus/components/timezone';
import isHotkey from 'is-hotkey';
import { DictionarySelectDisplay } from '../../toolympus/components/schemed/Select';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';

interface Props {
  data: ModerationFeedData;
  isFeedVisible?: boolean;
  setIsFeedVisible: (v: boolean) => void;
  loadBumper?: number;
}

const StepItem = styled.div<{ isResolved?: boolean }>`
  padding: 3px;
  background: #f4f4f4;
  opacity: ${props => props.isResolved ? 0.65 : 1};
  position: relative;
  cursor: pointer;
  
  & p {
    line-height: 1.1;
  }

  & .check {
    position: absolute;
    top: 3px;
    right: 3px;
    opacity: 0.5;
  }

  & .dates {
    justify-content: space-between;
    padding-top: 2px;
    align-items: flex-end;

    & .dates-comments {
      flex-flow: column;
      align-items: flex-start;
    }
  }

  & .comments-count {
    & svg {
      font-size: 0.75rem;
      margin-bottom: -2px;
    }
  }

  &:hover {
    opacity: 1;
  }
`;

const CommentItem = styled(StepItem)`
  white-space: pre-line;
  background: transparent;
  border: 1px solid ${ThemeSettings.colors.border};
`;

const NewCommentPaper = styled(Paper)`
  padding: 3px;
`;

export const ModerationPane = styled.div<{ parentPosition: number, isFeedVisible?: boolean, height: string }>`
  border-left: 2px solid ${props => props.isFeedVisible ? ThemeSettings.colors.border : "transparent"};
  padding: 0 12px 12px;
  padding-right: 12px;

  position: fixed;
  width: ${props => props.isFeedVisible ? 332 : 60}px;
  top: ${props => Math.max(0, props.parentPosition)}px;
  right: 16px;
  height: ${props => props.height};
  overflow-y: auto;

  display: flex;
  flex-flow: column;
  align-items: stretch;
  
  gap: 6px;

  & .add-comment-btn {
    align-self: flex-end;
  }
  
`;

export const ContentWithModerationWrapper = styled.div<{ isFeedVisible?: boolean }>`
  position: relative;

  padding-right: ${props => props.isFeedVisible ? 360 : 60}px;
`;



const getScrollParent = (node: Node | null): any => {
  if(!node) {
    return null;
  }

  if ((node as any).scrollHeight > (node as any).clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

export const DocumentModerationPane = (props: Props) => {
  const { data } = props;
  const formats = useFormats();

  const sendComment = () => {
    if(data.newComment.item?.comment?.trim()) {
      data.newComment.save(); 
    }
  }

  const [position, setPosition] = useThrottledState<number>(0);
  const paneRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      const pane = document.querySelector(".moderation-pane");
      if(pane) {
        pane.scrollTo({ top: pane.scrollHeight, behavior: "smooth" });
      }
    }, 100);
  }, [data.items, data.newComment.isEditing, props.isFeedVisible]);

  useEffect(() => {
    data.ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const scrollHandler = () => {
      
      if(paneRef.current && paneRef.current.parentElement) {
        const top = paneRef.current.parentElement.getBoundingClientRect()?.top;
        setPosition(top || 0);
      }
    }
    scrollHandler();
    setTimeout(scrollHandler, 200);
    const scrollableParent = getScrollParent(paneRef.current?.parentNode || null);
    if(scrollableParent) {
      scrollableParent.addEventListener("scroll", scrollHandler);
    }
    document.addEventListener("scroll", scrollHandler);
    document.addEventListener("resize", scrollHandler);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
      document.removeEventListener("resize", scrollHandler);
      if(scrollableParent) {
        scrollableParent.removeEventListener("scroll", scrollHandler);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.tasks.data.length, props.loadBumper]);

  return (
    <ModerationPane
      ref={paneRef}
      className="moderation-pane"
      parentPosition={position}
      isFeedVisible={props.isFeedVisible}
      height={position ? `${window.innerHeight - position}px` : "100%"}>

      {!props.isFeedVisible &&
        <Fab size="small" onClick={() => props.setIsFeedVisible(true)}>
          <CommentOutlined />
        </Fab>}

      {props.isFeedVisible && <>
      
        {data.items.filter(data.itemsFilter).map(item => {
          if(item.task) {
            const t = item.task;
            return (
              <StepItem key={t.task_id} isResolved={!data.showAllComments && !!t.is_resolved} onClick={() => data.setShowAllcomments(x => !x)}>
                <Typography className="title">{t.title}</Typography>
                {!!item.comments && <Typography variant="caption" className="comments-count"><CommentOutlined /> {item.comments}</Typography>}
                {!!t.is_resolved && <Check className="check" />}

                <Buttons className="dates">
                  <Typography variant="caption">{formats.formatDatetimeShort(utc.toLocal(t.created_at))}</Typography>
                  {!!t.resolved_at && <Typography variant="caption">{formats.formatDatetimeShort(utc.toLocal(t.resolved_at))}</Typography>}
                </Buttons>
              </StepItem>
            );
          } else if(item.comment) {
            const c = item.comment;
            return (
              <CommentItem key={c._id} isResolved={!data.showAllComments && c.is_resolved}>
                {c.comment}

                <Buttons className="dates">
                  <Buttons className="dates-comments">
                    <Typography variant="caption">
                      {formats.formatDatetimeShort(utc.toLocal(c.created_at))}, <DictionarySelectDisplay field="user_id" row={c} schema={{ dictionary: "Users" }} />
                    </Typography>

                    {!!c.resolved_at &&
                      <Typography variant="caption">
                        {formats.formatDatetimeShort(utc.toLocal(c.resolved_at))}, <DictionarySelectDisplay field="resolved_by" row={c} schema={{ dictionary: "Users" }} /> <Check color="inherit" fontSize="inherit" />
                      </Typography>}
                  </Buttons>

                  <Tooltip text={!c.is_resolved ? "Пометить комментарий как обработанный" : "Обработан"}>
                    <StrippedIconButton onClick={() => data.resolveComment.run(c)}>
                      {data.resolveComment.isRunning && data.resolvingComment?._id === c._id
                        ? <LoadingIndicator sizeVariant="s" color="secondary" />
                        : c.is_resolved ? <CheckCircleOutlineOutlined /> : <RadioButtonUnchecked />}
                    </StrippedIconButton>
                  </Tooltip>
                </Buttons>
              </CommentItem>
            );
          } else {
            return null;
          }})}


        {data.newComment.isEditing &&
          <NewCommentPaper>
            <FormGrid columns="1fr" noMargin>
              <TextField
                label="Комментарий"
                multiline
                fullWidth
                autoFocus
                value={data.newComment.item?.comment || ""}
                onChange={e => data.newComment.update({ comment: e.target.value })}
                onKeyDown={e => {
                  if(isHotkey("mod+enter", e)) {
                    sendComment();
                  }
                }}
                />
              <Button
                color="secondary"
                variant="contained"
                size="small"
                endIcon={<Send />}
                onClick={() => sendComment()}>
                  отправить
              </Button>
            </FormGrid>
          </NewCommentPaper>}

        <Buttons style={{ justifyContent: "space-between"}}>
          {data.isLoading ? <LoadingIndicator sizeVariant="s" /> : <div />}

          {!data.newComment.isEditing && <Tooltip text="Добавить комментарий">
            <Fab className="add-comment-btn" size="small" color="secondary" onClick={() => data.newComment.startEditing()}>
              <Add />
            </Fab>
          </Tooltip>}
        </Buttons>
      </>}


    </ModerationPane>
  );
}
