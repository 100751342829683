import React, { useCallback, useMemo, useState } from 'react';
import { createEditor, Descendant, Editor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history';
import { Leaf } from './Leaf';
import isHotkey from 'is-hotkey';
import { BlockElement, ParagraphElementType } from './BlockElements';
import { EditorToolbar } from './EditorToolbar';
import { ContextMenu, useContextMenu } from './Menu';
import { EditorCommand, EditorPlugin, PowerEditorProps } from './PowerEditorConfig';
import { StockCommands } from './StockCommands';
import { useIntl } from 'react-intl';
import { useLinksPlugin } from '../plugins/Links';
import { IPowerEditorContext, PowerEditorContextProvider } from './PowerEditorContext';
import { LineBreaksPlugin } from '../plugins/LineBreaks';
import { useBlockSettingsPlugin } from '../plugins/BlockSettings';
import { usePowerEditorSettings } from '../PowerEditorSettingsContext';
import { PasteHtmlPlugin } from '../plugins/PasteHtml';
import { BasicCommandsPlugin } from '../plugins/BasicCommands';
import { CommandPalette } from './Menu/CommandPalette';
import { useInlineImagesPlugin } from '../plugins/InlineImages';
import { BasicElementsPlugin } from '../plugins/BasicElements';
import { SettingsBlockPlugin } from '../plugins/SettingsBlock';
import { BackgroundColorsPlugin } from '../plugins/BasicCommands/BackgroundColorsPlugin';
import { useEditorFocusSave } from './useEditorFocusSave';

  
const initialValue: Descendant[] = [
    {
        type: ParagraphElementType,
        children: [{ text: "" }],
    },
];


export const withPlugins = (editor: Editor, plugins: EditorPlugin["inject"][]): Editor => {
    return (plugins || []).reduce(
        (e,plugin) => plugin ? plugin(e) : e,
        editor
    );
}

const pluginHotkeys = (plugins: EditorPlugin[]): EditorCommand[] => {
    return plugins.reduce(
        (commands, plugin) => ([...commands, ...(plugin.commands || [])]),
        [] as EditorCommand[])
        .filter(c => !!c.hotkey);
}

export const PowerEditorBase = (props: PowerEditorProps) => {
    const { viewMode } = props;

    const { formatMessage } = useIntl();
    const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState<boolean>(false);

    const linksPlugin = useLinksPlugin();
    const inlineImagesPlugin = useInlineImagesPlugin();
    const blockSettingsPlugin = useBlockSettingsPlugin();

    const settings = usePowerEditorSettings();

    const plugins = useMemo(
        () => [
            ...(props.noStockPlugins ? [] : [
              linksPlugin,
              inlineImagesPlugin,
              LineBreaksPlugin,
              BasicElementsPlugin,
              BasicCommandsPlugin,
              BackgroundColorsPlugin,
              PasteHtmlPlugin,
              SettingsBlockPlugin,
            ]),
            settings.noBlockSettings ? null : blockSettingsPlugin,
            ...(props.plugins || [])].filter(p => !!p) as EditorPlugin[],
        [props.plugins, settings.noBlockSettings, props.noStockPlugins, linksPlugin, inlineImagesPlugin, blockSettingsPlugin]);

    const pluginsKeys = plugins.map(p => p.key).join(";");

    const [editor] = useState(() => withPlugins(
        createEditor(), [
            withHistory,
            withReact,
            ...plugins.map(p => p.inject),
        ]));

    const focusSave = useEditorFocusSave(editor);
  

    const customBlocks = useMemo(
        () => plugins.reduce((r,plugin) => ({ ...r, ...plugin.customBlocks}), {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pluginsKeys]);

    const renderElement = useCallback(elementProps => (
        <BlockElement customBlocks={customBlocks} customRenderElement={props.customRenderElement} {...elementProps} />
        ), [customBlocks, props.customRenderElement]);

    const contextMenu = useContextMenu();


    const commands = useMemo(
        () => plugins.map(plugin => plugin.commands || []).reduce((all,fromPlugin) => [...all, ...fromPlugin], []),
        [plugins]);

    const invokeCommand: IPowerEditorContext["invokeCommand"] = useCallback(
        (name, editor, element, params) => {
            const command = commands.find(c => c.name === name);
            if(command && (!command.isAvailable || (editor && command.isAvailable(editor)))) {
                command.invoke(editor, element, params);
            }
        },
        [commands]);

    const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
      if(viewMode) {
          return;
      }

      for (const command of pluginHotkeys(plugins).concat(StockCommands)) {
          if(!command.isInternal && command.hotkey && isHotkey(command.hotkey, event) && (!command.isAvailable || command.isAvailable(editor))) {
              const handler = () => command.invoke(editor);
              if(handler() !== "continue") {
                  event.preventDefault();
                  return;
              };
          }
      }

      if(isHotkey("mod+shift+p", event)) {
          setIsCommandPaletteOpen(true);
          event.preventDefault();
          return;
      }

      if(!props.noLiteralTabs && isHotkey("tab", event)) {
          editor.insertText("\t");
          event.preventDefault();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, viewMode, props.noLiteralTabs, pluginsKeys]);

    return (
        <PowerEditorContextProvider viewMode={viewMode} invokeCommand={invokeCommand}>
            <Slate
                editor={editor}
                value={props.content?.blocks || initialValue}
                onChange={value => {
                    if(viewMode) {
                        return;
                    }

                    const isAstChange = editor.operations.some(o => o.type !== "set_selection");
                    if(isAstChange) {
                        props.update({
                            ...(props.content || {}),
                            blocks: value,
                        })
                    }
                }}>
                {!viewMode && props.showToolbar && <EditorToolbar />}
                <Editable
                    placeholder={props.placeholder || formatMessage({ id: "powerdoc.pages.placeholder" })}
                    onKeyDown={onKeyDown}
                    onContextMenu={viewMode ? undefined : contextMenu.onContextMenu}
                    renderElement={renderElement}
                    renderLeaf={settings.CustomLeaf || Leaf}
                    autoFocus={props.autoFocus}
                    readOnly={viewMode}
                    onFocus={focusSave.onFocus}
                    onBlur={focusSave.onBlur}
                    />

                {!props.noDialogs && plugins.map(plugin => plugin.dialogs)}

                {!props.noDialogs && <ContextMenu
                    editor={editor}
                    state={contextMenu}
                    config={props.menuConfig}
                    commands={commands}
                    />}

                {!props.noDialogs && <CommandPalette
                    editor={editor}
                    commands={commands}
                    isOpen={isCommandPaletteOpen}
                    close={() => setIsCommandPaletteOpen(false)}
                    />}
            </Slate>
        </PowerEditorContextProvider>
    );
}
